export const getCurrentScript = () =>
  document.currentScript ||
  (function currentScript() {
    const scripts = document.getElementsByTagName('script');

    return scripts[scripts.length - 1];
  })();

export const getUrlParameter = (queryString, name) => {
  try {
    const formattedName = name.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]');

    const regex = new RegExp(`[\\?&]${formattedName}=([^&#]*)`);

    const [, result] = regex.exec(`${queryString}`);

    return result === null || result === 'null' || result === 'undefined'
      ? ''
      : decodeURIComponent(result.replace(/\+/g, ' '));
  } catch (err) {
    return null;
  }
};
