import { html } from 'lit';
import { DEFAULT_TERMS } from '../../../../../helpers/constants';

const legalText = ({
  terms = DEFAULT_TERMS(window.parentHostname),
  isVisible = true,
} = {}) => html`
  <article style="${isVisible ? '' : 'display: none;'}">
    <div id="ps-desktop-widget__disclaimer-text">${terms}</div>
  </article>
`;

export default legalText;
