import { html } from 'lit';
import mapPopupPropertiesToCssVars from '../../common/popupCssVariable';
import { POPUP_NEW_COMPLIANCE_TEXT } from '../../../helpers/constants';
import { configStateStore } from '../../common/helpers/stateManager';

export const adjustMobileFont = (
  font,
  headlineFontSize,
  postHeadlineFontSize,
  defaultFontOverrides,
) => {
  // Hack to apply different default headline font size for open sans
  const getHeadlineFontSize = () => {
    const OPEN_SANS_CONDENSED = 'Open Sans Condensed:300';
    const DEFAULT_HEADLINE_FONT_SIZE = 14;
    const DEFAULT_OPEN_SANS_HEADLINE_FONT_SIZE = 19;

    // If font size is set just use that
    if (headlineFontSize) {
      return headlineFontSize;
    }

    // If font size isn't set and font is open sans
    if (font === OPEN_SANS_CONDENSED) {
      return DEFAULT_OPEN_SANS_HEADLINE_FONT_SIZE;
    }

    // Otherwise return default
    return DEFAULT_HEADLINE_FONT_SIZE;
  };

  const DEFAULT_FONT_ADJUSTMENTS = {
    preHeadline: '5.5vw',
    headline: `${getHeadlineFontSize()}vw`,
    postHeadline: `${postHeadlineFontSize}vw`,
    cta: '5.5vw',
    terms: '2.5vw',
    ...defaultFontOverrides,
  };

  const FONTS = [
    'Open Sans Condensed:300',
    'Merriweather',
    'Arial',
    'Roboto Mono',
    'Nunito Sans',
    'Roboto Slab',
    'Playfair Display',
    'Times New Roman',
    'Dancing Script',
    'Arimo',
    'EB Garamond',
    'Oswald',
    'Gothic A1',
    'IBM Plex Sans',
    'Kameron',
    'Cinzel',
    'Courier Prime',
    'Lato',
    'Muli',
    'PT Sans',
    'Roboto',
    'Inter',
  ];

  const fontSizes = FONTS.reduce(
    (agg, font) =>
      Object.assign(agg, { [font]: { ...DEFAULT_FONT_ADJUSTMENTS } }),
    {},
  );

  // Custom overrides
  fontSizes['Courier Prime'] = {
    ...fontSizes['Courier Prime'],
    preHeadline: '6vw',
    cta: '6vw',
  };

  return fontSizes;
};

const dynamicStyles = (popupThemeSettings) => {
  const {
    font,
    customCss,
    backgroundImage,
    borderRadius,
    buttonBackgroundColor,
    buttonTextColor,
    textColor,
    buttonRadius,
    backgroundStyle,
    style,
    headlineFontSize,
    postHeadlineFontSize = 5.5,
    logoUrl,
  } = popupThemeSettings;
  const { preHeadline, headline, postHeadline, terms, cta } = adjustMobileFont(
    font,
    headlineFontSize,
    postHeadlineFontSize,
  )[font];

  const configManager = configStateStore.getState();
  const { flags } = configManager;
  const hasNewComplianceText = flags?.includes(POPUP_NEW_COMPLIANCE_TEXT);

  // TODO: Fix custom font support. We are duplicating customCss at the top
  // and bottom of the file to support both css imports and cascade order.
  const template = `
    ${customCss || ''}
    #ps__container, #ps-mobile-widget__container, ::placeholder {
      font-family: ${font.split(':')[0]}, sans-serif;
    }

    ${
      logoUrl
        ? `
        #ps-mobile-widget-overlay__logo-container {
          margin-bottom: 24px;
        }
      `
        : ''
    }

    #ps-mobile-widget-overlay__pre-headline {
      font-size: ${preHeadline};
    }

    #ps-mobile-widget-overlay__headline,
    #ps-mobile-widget-partial__headline {
      font-size: ${headline};
    }

    #ps-mobile-widget-overlay__post-headline,
    #ps-mobile-widget-partial__sub-headline {
      font-size: ${postHeadline};
    }

    #ps-mobile-widget-overlay__terms,
      #ps-mobile-widget-partial__terms {
      font-size: ${terms};
    }

    #ps-mobile-widget-overlay__accept-button,
    #ps-mobile-widget-overlay__reject-button {
      font-size: ${cta};
    }

    ${
      backgroundStyle === 'Image'
        ? `
        #ps-mobile-widget-partial__container,
        #ps-mobile-widget-overlay__container {
          background-image: url(${backgroundImage});
        }
      `
        : ''
    }

    ${
      backgroundImage
        ? `
        #ps-mobile-widget-overlay__pre-headline,
        #ps-mobile-widget-overlay__post-headline {
          font-weight: bold;
        }
      `
        : ''
    }

    #ps-mobile-widget-partial__container,
    #ps-mobile-widget-overlay__container {
      color: ${textColor};
    }

    #ps-mobile-widget-partial__container {
      border-radius: ${borderRadius}px;
    }

    ::placeholder {
      color: ${textColor};
    }

    #ps-mobile-widget-overlay__accept-button {
      border-color: ${buttonBackgroundColor};
    }

    #ps-mobile-widget-partial__phone-submit > svg {
      color: ${buttonTextColor};
    }

    #ps-mobile-widget-overlay__phone-input-wrapper {
      border-radius: ${style === 'Square' ? 0 : buttonRadius}px;
    }

    #ps-mobile-widget-overlay__phone-input-wrapper > .iti,
    #ps-mobile-widget-partial__phone-input-wrapper > .iti {
      border-color: ${buttonBackgroundColor};
      border-top-left-radius: ${buttonRadius}px;
      border-bottom-left-radius: ${buttonRadius}px;
    }

    #ps-mobile-widget-overlay__phone-input-wrapper > .iti,
    #ps-mobile-widget-overlay__buttons--opt-in,
    #ps-mobile-widget-overlay__accept-button {
      border-radius: ${buttonRadius}px;
    }

    #ps-mobile-widget-overlay__buttons--opt-in,
    #ps-mobile-widget-overlay__accept-button {
      border-color: ${buttonBackgroundColor};
    }

    #ps-mobile-widget-partial__phone-submit {
      border-color: ${buttonBackgroundColor};
      border-top-right-radius: ${buttonRadius}px;
      border-bottom-right-radius: ${buttonRadius}px;
    }

    ${
      hasNewComplianceText
        ? `
        #ps-mobile-widget-partial__terms {
          font-size: 2.3vw;
        }
      `
        : ''
    }

    ${mapPopupPropertiesToCssVars(popupThemeSettings)}

    ${customCss || ''}
`.trim();

  return html`
    <style>
      ${template}
    </style>
  `;
};

export default dynamicStyles;
