import * as Sentry from '@sentry/browser';
import { SPLIT_TEST_QUERY_STRING_PARAMETER } from '../../helpers/constants';
import { getCurrentScript, getUrlParameter } from '../../helpers/scripts';
import {
  checkCountryAndLogToSentry,
  extractHostname,
} from '../../helpers/utility';
import { fetchConfig } from '../../helpers/utils';
import { initializeSentry } from '../../initSentry';
import { Popup } from '../types/popup';
import { configStateStore } from '../common/helpers/stateManager';
import { Disclaimer } from '../../sdk/subscriber-events/utils';

export interface IframeVariables {
  shopId: string;
  status: string | null;
  origin: string | null;
  sessionId: string | null;
  previewData?: Popup;
  flags: string | null;
  shopShop: string | null;
  splitTestCookies: string | null;
  disclaimer: Disclaimer | null;
  currentCountry: string | null;
  subscriberId: string | null;
  uniqueImpressionCookies: string | null;
}

const getDisclaimerDataForPopupPreview = async (shopId: string) => {
  const config = await fetchConfig(shopId);

  return config?.data?.disclaimer ?? null;
};

const extractVariablesFromIframe = async (): Promise<IframeVariables> => {
  const queryString = `?${(getCurrentScript() as HTMLScriptElement).src
    .split('?')
    .slice(1)
    .join('')}`;

  const shopId = getUrlParameter(queryString, 'shopId');
  const shopShop = getUrlParameter(queryString, 'shopShop');

  const status = getUrlParameter(queryString, 'status') || null;

  const splitTestCookies =
    getUrlParameter(queryString, SPLIT_TEST_QUERY_STRING_PARAMETER) || null;

  const uniqueImpressionCookies =
    getUrlParameter(queryString, 'uniqueImpressionCookies') || null;

  const sessionId = getUrlParameter(queryString, 'sessionId') || null;

  const subscriberId = getUrlParameter(queryString, 'subscriberId');
  const origin = getUrlParameter(queryString, 'origin');
  const flags = getUrlParameter(queryString, 'flags');
  const config = getUrlParameter(queryString, 'config');
  const disclaimer = getUrlParameter(queryString, 'disclaimer');
  const parsedDisclaimer = disclaimer
    ? JSON.parse(disclaimer)
    : await getDisclaimerDataForPopupPreview(shopId as string);

  if (!parsedDisclaimer) {
    throw new Error('Disclaimer data could not be loaded from config');
  }

  const currentCountry =
    getUrlParameter(queryString, 'country') ||
    (await checkCountryAndLogToSentry(shopId));

  let previewData;

  if (config) {
    previewData = JSON.parse(config);
  }

  return {
    shopId: shopId ?? '',
    status,
    origin,
    sessionId,
    previewData,
    flags,
    shopShop,
    splitTestCookies,
    disclaimer: parsedDisclaimer,
    currentCountry,
    subscriberId,
    uniqueImpressionCookies,
  };
};

export const initializeIframeGlobalState =
  async (): Promise<IframeVariables> => {
    const queryString = `?${(getCurrentScript() as HTMLScriptElement).src
      .split('?')
      .slice(1)
      .join('')}`;

    // Sets up error logging
    initializeSentry(!!getUrlParameter(queryString, 'config'));

    const iframeVariables = await extractVariablesFromIframe();
    const { flags, shopId, previewData, shopShop, origin, disclaimer } =
      iframeVariables;

    Sentry.setTag('shop_id', shopId);
    Sentry.setTag('editor_preview', !!previewData);

    // Sets up global state
    configStateStore.updateState({
      flags: flags ?? undefined,
      shopId: Number(shopId),
      shopShop: shopShop ?? undefined,
      disclaimer: disclaimer ?? undefined,
      isPreview: Boolean(previewData),
    });

    // Stores the parent pages origin
    window.parentHostname = extractHostname(origin);

    return iframeVariables;
  };
