import { captureException } from '@sentry/browser';
import { ScreenSize, loadFontsForPopup } from '@stodge-inc/block-rendering';
import { BlockPopupStateManager } from '../BlockPopupStateManager';
import {
  POPUP_STATUS_COOKIE_NAME_PREFIX,
  POPUP_TRIGGER_TYPES,
  POPUP_UNIQUE_IMPRESSION_COOKIE_NAME,
} from '../../../../helpers/constants';
import {
  filterSplitTestPopups,
  getBlockPopupByTriggerType,
  getValidBlockPopups,
} from '../../../../services/popup/helper';
import { configStateStore } from '../../helpers/stateManager';
import {
  getCookieValueByKey,
  extractCookieValuesByPrefix,
} from '../../../../helpers/utils';
import { getBlockPopups, getCustomFonts } from './api';

const fetchAndInitializeBlockPopups = async (
  statuses: string,
  currentCountry: string,
  origin: string,
  sessionId: string,
  subscriberId: string,
  shopId: number,
  viewport: ScreenSize,
  splitTestCookies: string | null,
  uniqueImpressionCookiesAsString: string | null,
): Promise<boolean> => {
  const { disclaimer, flags } = configStateStore.getState();

  if (!disclaimer) return false;

  try {
    const blockPopups = await getBlockPopups(shopId);

    const filteredSplitTestPopups = filterSplitTestPopups(
      blockPopups,
      splitTestCookies,
    );

    const validBlockPopups = getValidBlockPopups({
      blockPopups: filteredSplitTestPopups,
      currentCountry,
      origin,
      statuses,
      subscriberId,
      viewport,
    });

    const timeDelayBlockPopup = getBlockPopupByTriggerType(
      validBlockPopups,
      POPUP_TRIGGER_TYPES.DELAY,
    );

    if (!timeDelayBlockPopup) {
      return false;
    }

    // TODO: call in BlockPopupStateManager to keep everything encapsulated?
    // Start loading fonts as soon as possible
    loadFontsForPopup(
      timeDelayBlockPopup,
      viewport,
      await getCustomFonts(shopId),
    );

    const timeDelayBlockPopupStatus = getCookieValueByKey(
      POPUP_STATUS_COOKIE_NAME_PREFIX,
      timeDelayBlockPopup.id,
      statuses,
    );

    const uniqueImpressionCookiesObject = extractCookieValuesByPrefix(
      POPUP_UNIQUE_IMPRESSION_COOKIE_NAME,
      uniqueImpressionCookiesAsString,
    );

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const timeDelayBlockPopupStateManager = new BlockPopupStateManager({
      currentCountry,
      disclaimer,
      enabledFeatureFlags: flags?.split(',') ?? [],
      origin,
      popup: timeDelayBlockPopup,
      sessionId,
      shopId,
      status: timeDelayBlockPopupStatus ?? null,
      subscriberId,
      theme: timeDelayBlockPopup.theme,
      viewport,
      uniqueImpressionCookies: uniqueImpressionCookiesObject,
    });

    return true;
  } catch (error) {
    // no op
    // eslint-disable-next-line no-console
    console.error(`Error initializing block popups: ${error}`);
    captureException(error);
    return false;
  }
};

export default fetchAndInitializeBlockPopups;
