import { html } from 'lit';
import { captureException } from '@sentry/browser';
import { FADE_IN_CLASS, handlePopupKeyDown } from '../../../helpers/ui';
import '../../forms/customForm';
import { PopupPage, PopupTemplateProps } from '../../types/popup';
import customFormWrapper from './customFormWrapper';
import doubleOptInSuccess from './doubleOptInSuccess';

type MainProps = PopupTemplateProps & {
  onExit: (hardClose?: boolean) => void;
  page: PopupPage;
};

const main = (props: MainProps) => {
  const { onExit, popupSize, screen } = props;
  const page = (props.pages as PopupPage[] | undefined)?.find(
    (p: PopupPage) => screen === `page_${p.id}`,
  );
  const isDoubleOptInSuccess = screen === 'success';
  const isBannerPopupSize = popupSize === 'Banner';

  if (!page && !isDoubleOptInSuccess) {
    captureException(
      new Error(`No render function found for screen: ${screen}`),
    );
    return;
  }

  const v2FormRenderFn = page ? customFormWrapper : undefined;

  const doubleOptInRenderFn = isDoubleOptInSuccess
    ? doubleOptInSuccess
    : undefined;

  return html`
    <div
      id="ps-desktop-widget__container"
      class="${FADE_IN_CLASS}"
      aria-modal="true"
      role="dialog"
      aria-labelledby="ps-desktop-widget__headline ps-widget__header"
      aria-describedby="desktop-widget__aria-description"
      @keydown=${handlePopupKeyDown(onExit)}
    >
      ${isBannerPopupSize
        ? html`
            <div
              id="ps-desktop-widget__body"
              style="position: absolute; bottom: 0; left: 0; width: 100vw; max-width: 100vw; margin: 0;"
            >
              <div
                style="display: flex; flex-direction: column; width: 50vw; margin: 0 auto; text-align: center;"
                data-cy="desktop-banner-popup-container"
              >
                ${v2FormRenderFn?.({ ...props, page }) ??
                doubleOptInRenderFn?.({
                  logoUrl: props.logoUrl as string,
                  onExit,
                })}
              </div>
            </div>
          `
        : html`
            <div id="ps-desktop-widget__background">
              <div
                id="ps-desktop-width__click-background"
                @click=${onExit}
              ></div>
              <div id="ps-desktop-widget__body-container">
                <div id="ps-desktop-widget__side-img"></div>
                <div
                  id="ps-desktop-widget__body"
                  data-cy="desktop-popup-container"
                >
                  ${v2FormRenderFn?.({ ...props, page }) ??
                  doubleOptInRenderFn?.({
                    logoUrl: props.logoUrl as string,
                    onExit,
                  })}
                </div>
              </div>
            </div>
          `}
    </div>
  `;
};

export default main;
