import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
const deleteIcon = (onDelete) => html `<div
  class="editor-node__button"
  @click=${onDelete}
>
  <svg
    class="editor-node__button-svg"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75 7.75l.841 9.673a2 2 0 001.993 1.827h5.832a2 2 0 001.993-1.827l.841-9.673H5.75zM9.75 10.75v5.5M13.25 10.75v5.5M8.75 7.75v-1a2 2 0 012-2h1.5a2 2 0 012 2v1M4.75 7.75h13.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</div>`;
const duplicateIcon = (onDuplicate) => html `<div
  class="editor-node__button"
  @click=${onDuplicate}
>
  <svg
    class="editor-node__button-svg"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M6.5 15.25v0a1.75 1.75 0 01-1.75-1.75V6.75a2 2 0 012-2h6.75c.966 0 1.75.784 1.75 1.75v0"
    />
    <rect
      width="10.5"
      height="10.5"
      x="8.75"
      y="8.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      rx="2"
    />
  </svg>
</div>`;
const renderEditorNode = (editorClasses, environment, blockActions) => {
    const { duplicateBlock, deleteBlock } = blockActions;
    if (!environment.isDevelopment) {
        return nothing;
    }
    const handleClick = (e) => e.stopPropagation();
    return html `
    <div class=${classMap(editorClasses)}>
      ${duplicateBlock || deleteBlock
        ? html `<div class="editor-node__button-row" @click=${handleClick}>
            ${duplicateBlock ? duplicateIcon(duplicateBlock) : nothing}
            ${deleteBlock ? deleteIcon(deleteBlock) : nothing}
          </div>`
        : nothing}
    </div>
  `;
};
export default renderEditorNode;
