import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, DEFAULT_COLORS, IMAGE_STYLE_ELEMENT, STYLE_RULE_NAMES, } from '../constants';
import renderEditorNode from '../development/editorNode';
const IMAGE_BLOCK_STYLE_ELEMENTS = Object.assign(Object.assign({}, BLOCK_STYLE_ELEMENT), IMAGE_STYLE_ELEMENT);
const defaultStyles = {
    [IMAGE_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.ALIGN_SELF]: 'center',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.WIDTH]: '100px',
            // private
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
    [IMAGE_BLOCK_STYLE_ELEMENTS.IMAGE]: {
        mobile: {
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.HEIGHT]: 'auto',
            [STYLE_RULE_NAMES.MAX_WIDTH]: '100%',
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'block',
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
    },
};
function render(renderData) {
    var _a, _b, _c;
    const { blockActions, classes, environment } = renderData;
    const { block: blockClasses, image: imageClasses, editorNode: editorNodeClasses, } = classes;
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${renderEditorNode(editorNodeClasses, environment, blockActions)}
      <img
        src=${ifDefined((_a = renderData.block.config) === null || _a === void 0 ? void 0 : _a.src)}
        class=${classMap(imageClasses)}
        alt=${(_c = (_b = renderData.block.config) === null || _b === void 0 ? void 0 : _b.altText) !== null && _c !== void 0 ? _c : ''}
      />
    </div>
  `;
}
const imageBlockHelpers = {
    defaultStyles,
    render,
    styleElements: IMAGE_BLOCK_STYLE_ELEMENTS,
};
export default imageBlockHelpers;
