import { CSS_VARS, ROOT_GRID_AREAS, ROOT_GRID_COLUMNS, ROOT_LAYOUTS, } from '../../constants';
/**
 * Extracts the URL from a background image css string between the parentheses of
 * url(). If the string doesn't fit the pattern, the original string or undefined
 * is returned.
 */
export const extractUrlFromBackgroundImage = (url) => {
    var _a;
    if (!url)
        return undefined;
    const regex = /url\((.*?)\)/;
    const match = url.match(regex);
    return (_a = match === null || match === void 0 ? void 0 : match[1]) !== null && _a !== void 0 ? _a : url;
};
/**
 * Creates a grid-template-areas row that with one area.
 */
const getFullRow = (rowType) => rowType === ROOT_GRID_AREAS.DECORATIVE
    ? `"${`${ROOT_GRID_AREAS.DECORATIVE} `.repeat(ROOT_GRID_COLUMNS)}"`
    : `"${`${ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER} `.repeat(ROOT_GRID_COLUMNS)}"`;
/**
 * Creates a grid-template-areas row that is divided into decorative and main
 * popup container areas. Assumes 6 columns for both areas for now, but will
 * likely need to be able to handle custom column counts in the future.
 */
const getSplitRow = (rootLayout) => {
    const half = ROOT_GRID_COLUMNS / 2;
    return rootLayout === ROOT_LAYOUTS.LEFT_DECORATIVE
        ? // prettier-ignore
            `"${`${ROOT_GRID_AREAS.DECORATIVE} `.repeat(half)} ${`${ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER} `.repeat(half)}"`.trim()
        : // prettier-ignore
            `"${`${ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER} `.repeat(half)} ${`${ROOT_GRID_AREAS.DECORATIVE} `.repeat(half)}"`.trim();
};
/* TODO(Kameron Ahler): The layoutDirection and layoutSpacing functions in here
could probably be refactored and removed with a little more effort. They used to
have more complicated side effects, but they now simply point to a static CSS
property. Changing these would have an impact on existing popups though. */
const layoutDirection = (layoutDirection) => `flex-direction: ${layoutDirection};`;
const layoutSpacing = (layoutSpacing) => `justify-content: ${layoutSpacing};`;
const rootLayout = (rootLayout) => {
    if (rootLayout === ROOT_LAYOUTS.NONE)
        // prettier-ignore
        return `
      grid-template-areas: ${getFullRow(ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER)};
      grid-template-rows: auto;
    `.trim();
    if (rootLayout === ROOT_LAYOUTS.TOP_DECORATIVE)
        // prettier-ignore
        return `
      grid-template-areas: ${getFullRow(ROOT_GRID_AREAS.DECORATIVE)} ${getFullRow(ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER)};
      grid-template-rows: auto 1fr;
    `.trim();
    if (rootLayout === ROOT_LAYOUTS.BOTTOM_DECORATIVE)
        // prettier-ignore
        return `
      grid-template-areas: ${getFullRow(ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER)} ${getFullRow(ROOT_GRID_AREAS.DECORATIVE)};
      grid-template-rows: 1fr auto;
    `.trim();
    if (rootLayout === ROOT_LAYOUTS.LEFT_DECORATIVE)
        return `
      grid-template-areas: ${getSplitRow(ROOT_LAYOUTS.LEFT_DECORATIVE)};
      grid-template-rows: auto;
    `.trim();
    return `
    grid-template-areas: ${getSplitRow(ROOT_LAYOUTS.RIGHT_DECORATIVE)};
    grid-template-rows: auto;
  `.trim();
};
const spinToWinDuration = (duration) => `${CSS_VARS.SPIN_TO_WIN_DURATION}: ${duration};`;
// @property defined in base popup CSS.
const backgroundImage = (backgroundImage) => backgroundImage.startsWith('linear-gradient(') ||
    backgroundImage.startsWith('radial-gradient(')
    ? `--background-image: ${backgroundImage};`
    : // Some old popups will have `url()` as part of the string, so the style rule value needs to be normalized.
        `--background-image: url(${extractUrlFromBackgroundImage(backgroundImage)});`;
// @property defined in base popup CSS
const backgroundOpacity = (backgroundOpacity) => `--background-opacity: ${backgroundOpacity};`;
// @property defined in base popup CSS
const backgroundPosition = (backgroundPosition) => `--background-position: ${backgroundPosition};`;
// @property defined in base popup CSS
const backgroundRepeat = (backgroundRepeat) => `--background-repeat: ${backgroundRepeat};`;
// @property defined in base popup CSS
const backgroundSize = (backgroundSize) => `--background-size: ${backgroundSize};`;
export const CustomStyleRules = {
    backgroundImage,
    backgroundOpacity,
    backgroundPosition,
    backgroundRepeat,
    backgroundSize,
    layoutDirection,
    layoutSpacing,
    rootLayout,
    spinToWinDuration,
};
