import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, CSS_VARS, DEFAULT_COLORS, PATH_STYLE_ELEMENT, SPIN_TO_WIN_CENTER_ELEMENT, SPIN_TO_WIN_DEFAULT_ROTATIONS, SPIN_TO_WIN_MAIN_WHEEL_ELEMENT, SPIN_TO_WIN_SECTION_BORDER_ELEMENT, SPIN_TO_WIN_STOPPER_POSITIONS, STYLE_RULE_NAMES, SVG_STYLE_ELEMENT, TEXT_STYLE_ELEMENT, } from '../../constants';
import renderEditorNode from '../../development/editorNode';
import { getFlattenedViewportProps } from '../../utils';
import { getAnimationRotation, getMedianSectionAngle, getOuterMarginAdjustedToStopperPosition, } from '../../utils/spinToWin';
import spinToWinSectionBorders, { defaultStyles as sectionBordersDefaultStyles, } from './spinToWinSectionBorders';
import spinToWinSections from './spinToWinSections';
import spinToWinStopper, { defaultStyles as stopperDefaultStyles, } from './spinToWinStopper';
import spinToWinText, { defaultStyles as textDefaultStyles, } from './spinToWinText';
const SVG_ROTATION_CORRECTION = 90; // SVGs start at 3 o'clock
const STOPPER_ROTATION = {
    [SPIN_TO_WIN_STOPPER_POSITIONS.TOP]: 0,
    [SPIN_TO_WIN_STOPPER_POSITIONS.RIGHT]: 90,
    [SPIN_TO_WIN_STOPPER_POSITIONS.BOTTOM]: 180,
    [SPIN_TO_WIN_STOPPER_POSITIONS.LEFT]: 270,
};
const SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, BLOCK_STYLE_ELEMENT), PATH_STYLE_ELEMENT), SPIN_TO_WIN_CENTER_ELEMENT), SPIN_TO_WIN_MAIN_WHEEL_ELEMENT), SPIN_TO_WIN_SECTION_BORDER_ELEMENT), SVG_STYLE_ELEMENT), TEXT_STYLE_ELEMENT);
const defaultStyles = Object.assign(Object.assign(Object.assign({ [SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.ALIGN_SELF]: 'center',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.SPIN_TO_WIN_DURATION]: '8000ms',
            [STYLE_RULE_NAMES.WIDTH]: '100%',
            // private
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    }, [SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS.SPIN_TO_WIN_CENTER]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.SPIN_TO_WIN_CENTER_BG,
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.HEIGHT]: '20px',
            [STYLE_RULE_NAMES.WIDTH]: '20px',
            // private
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '50%',
            [STYLE_RULE_NAMES.INSET]: '50% auto auto 50%',
            [STYLE_RULE_NAMES.POSITION]: 'absolute',
            [STYLE_RULE_NAMES.TRANSFORM]: 'translate(-50%, -50%)',
        },
    }, [SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS.SPIN_TO_WIN_MAIN_WHEEL]: {
        mobile: {
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'solid',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '2px',
            // private
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '50%',
            [STYLE_RULE_NAMES.INSET]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.POSITION]: 'absolute',
        },
    } }, sectionBordersDefaultStyles), stopperDefaultStyles), textDefaultStyles);
function render({ block, blockActions, classes, environment, finalStyleRules, state, }) {
    var _a, _b, _c, _d;
    if (!((_a = block.config) === null || _a === void 0 ? void 0 : _a.spinToWin))
        return nothing;
    const { isDevelopment, viewport } = environment;
    const { handleReorderDragEnd, handleReorderDragStart, handleReorderDrop, selectBlock, } = blockActions;
    const { animation, sections, stopper, startingSection } = block.config.spinToWin;
    const { isSpinning = false } = state !== null && state !== void 0 ? state : {};
    const { block: blockClasses, editorNode: editorNodeClasses, path: stopperPathClasses, spinToWinSectionBorder: borderClasses, spinToWinCenter: centerClasses, spinToWinMainWheel: mainWheelClasses, svg: stopperSvgClasses, text: textClasses, } = classes;
    const sortedSections = (_b = [...sections].sort((a, b) => a.order - b.order)) !== null && _b !== void 0 ? _b : sections;
    /* Rearrange so the first section is the one the user specified, and in order
    from there forward, looping back around to the start of the array. This
    helps keep math for rotation a little simpler down the line. */
    const reorderedSections = [
        ...sortedSections.slice(startingSection),
        ...sortedSections.slice(0, startingSection),
    ];
    const { stopperPosition = SPIN_TO_WIN_STOPPER_POSITIONS.BOTTOM } = (_c = getFlattenedViewportProps(viewport, stopper.position)) !== null && _c !== void 0 ? _c : {};
    const stopperRotationOffset = STOPPER_ROTATION[stopperPosition];
    /* Applied to a parent element to keep things simple. The children are rotated
    relative to this initial rotation. */
    const baseRotationForAnimatedElements = stopperRotationOffset -
        getMedianSectionAngle(reorderedSections) -
        SVG_ROTATION_CORRECTION -
        getMedianSectionAngle(reorderedSections) / 3;
    const animationRotation = getAnimationRotation({
        rotations: (_d = animation === null || animation === void 0 ? void 0 : animation.rotations) !== null && _d !== void 0 ? _d : SPIN_TO_WIN_DEFAULT_ROTATIONS,
        startingSection,
        sections: reorderedSections,
    });
    // prettier-ignore
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(selectBlock)}
      @dragstart=${ifDefined(handleReorderDragStart)}
      @dragend=${ifDefined(handleReorderDragEnd)}
      @drop=${ifDefined(handleReorderDrop)}
    >
      <!-- Editor -->
      ${renderEditorNode(editorNodeClasses, environment, blockActions)}

      <div style="position: relative; ${getOuterMarginAdjustedToStopperPosition(stopperPosition)}">
        <div style="transform: rotate(${baseRotationForAnimatedElements}deg)">

          <!-- Animated -->
          <style>
            :root,
            :host {
              ${CSS_VARS.SPIN_TO_WIN_ROTATION}: ${animationRotation}deg;
            }
          </style>
          <div class="animation ${isSpinning ? 'animation--spin-to-win' : ''} spin-to-win__animation-wrapper">
            ${spinToWinSections(reorderedSections, viewport)}
            ${spinToWinSectionBorders(classMap(borderClasses), reorderedSections)}
            <div class=${classMap(mainWheelClasses)}></div>
            ${spinToWinText({
        finalStyleRules,
        psCssClasses: textClasses,
        sections: reorderedSections,
        viewport,
    })}
            </div>
          </div>

          <!-- Static -->
          <div class=${classMap(centerClasses)}></div>
          ${spinToWinStopper({
        finalStyleRules,
        position: stopperPosition,
        psCssClasses: { stopperPathClasses, stopperSvgClasses },
        stopperRotationOffset,
        type: stopper.type
    })}

      </div>
    </div>
  `;
}
const spinToWinBlockHelpers = {
    defaultStyles,
    render,
    styleElements: SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS,
};
export default spinToWinBlockHelpers;
