import { html } from 'lit';
import mapPopupPropertiesToCssVars from '../../common/popupCssVariable';
import { POPUP_NEW_COMPLIANCE_TEXT } from '../../../helpers/constants';
import { configStateStore } from '../../common/helpers/stateManager';

const getBodyBorderRadius = ({ fullscreen, sideImgUrl, borderRadius }) => {
  if (fullscreen) {
    return 0;
  }
  return sideImgUrl
    ? `0 ${borderRadius}px ${borderRadius}px 0`
    : `${borderRadius}px`;
};

const getSideImageBorderRadius = ({ sideImgUrl, borderRadius }) =>
  sideImgUrl ? `${borderRadius}px 0 0 ${borderRadius}px` : '0';

const dynamicStyles = (popupThemeSettings) => {
  const {
    font,
    customCss,
    backgroundImage,
    backgroundStyle,
    headlineColor,
    exitButtonColor,
    borderRadius,
    buttonRadius,
    fullscreen,
    sideImgUrl,
    logoUrl,
  } = popupThemeSettings;

  const configManager = configStateStore.getState();
  const { flags } = configManager;
  const hasNewComplianceText = flags
    ?.split(',')
    .includes(POPUP_NEW_COMPLIANCE_TEXT);

  // TODO: Fix custom font support. We are duplicating customCss at the top
  // and bottom of the file to support both css imports and cascade order.
  const template = `
    ${customCss || ''}

    #ps-desktop-widget__container,
    #ps-desktop-widget__container button,
    #ps-desktop-widget__container input {
      font-family: ${font.split(':')[0]}, sans-serif;
    }

    #ps-desktop-widget__body {
    border-radius: ${getBodyBorderRadius({
      fullscreen,
      sideImgUrl,
      borderRadius,
    })};
      background-image: ${
        backgroundStyle === 'Image' ? `url('${backgroundImage}')` : 'none'
      };
      height: ${fullscreen ? '100%' : 'auto'};

      ${
        fullscreen
          ? `
        display: flex;
        justify-content: center;
      `
          : ''
      }
    }

    main {
      max-width: ${fullscreen ? '500px' : 'none'};
    }


    section {
      color: ${headlineColor};
      margin: ${
        fullscreen ? '40px 0 60px' : logoUrl ? '20px 0 30px' : '30px 0 40px'
      };
    }

    #ps-desktop-widget__close > svg {
      fill: ${exitButtonColor};
    }

    #ps-desktop-widget__phone-input-wrapper,
    #ps-desktop-widget__phone-input-wrapper input {
      border-radius: ${buttonRadius}px;
    }

    #ps-desktop-widget__submit {
      border-radius: ${buttonRadius}px;
    }

    #ps-desktop-widget__side-img {
      display: ${fullscreen ? 'none' : 'block'};
      width: ${sideImgUrl ? '400px' : '0'};
      border-radius: ${getSideImageBorderRadius({ sideImgUrl, borderRadius })};
      background-image: ${sideImgUrl ? `url('${sideImgUrl}')` : 'none'};
      background-size: cover;
    }

    ${
      hasNewComplianceText
        ? `
        #ps-desktop-widget__disclaimer-text {
          text-align: center;
          line-height: 1;
        }
      `
        : ''
    }

    ${mapPopupPropertiesToCssVars(popupThemeSettings)}

    ${customCss || ''}
`.trim();

  return html`
    <style>
      ${template}
    </style>
  `;
};

export default dynamicStyles;
