import { adjustMobileFont } from '../../../../../widget/mobile/components/dynamicStyles';

/**
 * @param {import("../../widget").PopupPreferences} props
 */
const dynamicMobileStyles = ({
  font,
  customCss,
  backgroundColor,
  backgroundImage,
  buttonBackgroundColor,
  buttonTextColor,
  textColor,
  buttonRadius,
  backgroundStyle,
  style,
  headlineFontSize,
  postHeadlineFontSize = 5.5,
}) => {
  const { preHeadline, headline, postHeadline, terms, cta } = adjustMobileFont(
    font,
    headlineFontSize,
    postHeadlineFontSize,
    {
      terms: '2.4vw',
    },
  )[font];

  return `
        ${customCss || ''}
        #ps-bis-widget-mobile-modal__container,
        #ps-mobile-widget__container, ::placeholder,
        #ps-bis-widget-mobile-modal__container .ps-heading {
            font-family: ${font.split(':')[0]}, sans-serif !important;
        }

        #ps-mobile-widget-overlay__pre-headline {
            font-size: ${preHeadline};
        }

        #ps-mobile-widget-overlay__headline,
        #ps-mobile-widget-partial__headline {
            font-size: ${headline};
        }

        #ps-mobile-widget-overlay__post-headline {
            font-size: ${postHeadline};
        }

        #ps-mobile-widget-overlay__terms,
        #ps-mobile-widget-partial__terms {
            font-size: ${terms};
        }

        #ps-mobile-widget-overlay__accept-button {
            font-size: ${cta};
        }

        ${
          backgroundStyle === 'Image'
            ? `
            #ps-mobile-widget-partial__container,
            #ps-mobile-widget-overlay__container {
                background-image: url(${backgroundImage});
            }
        `
            : ''
        }

        ${
          backgroundImage
            ? `
            #ps-mobile-widget-overlay__pre-headline,
            #ps-mobile-widget-overlay__post-headline {
                font-weight: bold;
            }
        `
            : ''
        }

        #ps-mobile-widget-partial__container,
        #ps-mobile-widget-overlay__container {
            background-color: ${backgroundColor};
            color: ${textColor};
            width: 100%;
        }

        ::placeholder,
        #ps-mobile-widget-partial__container a,
        #ps-mobile-widget-overlay__container a {
            color: ${textColor};
        }
        #ps-mobile-widget-close__body,
        #ps-mobile-widget-overlay__accept-button {
            background-color: ${buttonBackgroundColor};
            border-color: ${buttonBackgroundColor};
        }

        #ps-mobile-widget-close__text,
        #ps-mobile-widget-overlay__accept-button-text,
        #ps-mobile-widget-partial__phone-submit > svg {
            color: ${buttonTextColor};
        }

        #ps-mobile-widget-overlay__phone-input-wrapper {
            border-radius: ${style === 'Square' ? 0 : buttonRadius}px;
        }

        #ps-mobile-widget-overlay__phone-input-wrapper > .iti,
        #ps-mobile-widget-partial__phone-input-wrapper > .iti {
            border-color: ${buttonBackgroundColor};
            border-top-left-radius: ${buttonRadius}px;
            border-bottom-left-radius: ${buttonRadius}px;
        }

        #ps-mobile-widget-close__body,
        #ps-mobile-widget-overlay__phone-input-wrapper > .iti,
        #ps-mobile-widget-overlay__buttons--opt-in,
        #ps-mobile-widget-overlay__accept-button {
            border-radius: ${buttonRadius}px;
        }

        #ps-mobile-widget-overlay__buttons--opt-in,
        #ps-mobile-widget-overlay__accept-button {
            border-color: ${buttonBackgroundColor};
        }

        #ps-mobile-widget-partial__phone-submit {
            background-color: ${buttonBackgroundColor};
            border-color: ${buttonBackgroundColor};
            border-top-right-radius: ${buttonRadius}px;
            border-bottom-right-radius: ${buttonRadius}px;
        }

        #ps-mobile-widget-overlay__terms,
        #ps-mobile-widget-partial__terms {
            color: ${textColor};
        }

        #ps-mobile-widget-overlay__terms a,
        #ps-mobile-widget-partial__terms a {
            color: ${textColor};
            display: inline-block;
            text-transform: uppercase;
            text-decoration: underline;
        }

        #ps-bis-widget-bubble__close-widget > svg {
            fill: ${buttonTextColor};
        }

        ${customCss || ''}
    `.trim();
};

export default dynamicMobileStyles;
