import { html } from 'lit';
import { V2_OPT_IN_SOURCES } from '../../../helpers/constants';
import { handleKeyDownForClickableDivs } from '../../../helpers/ui';
import { CUSTOM_CSS_SELECTOR_PREFIX } from '../../forms/constants';
import { PopupPage, PopupTemplateProps } from '../../types/popup';

type CustomFormWrapperProps = PopupTemplateProps & {
  onExit: () => void;
  page?: PopupPage;
};

const customFormWrapper = ({
  onExit,
  page,
  ...props
}: CustomFormWrapperProps) => html`
  <main id="${CUSTOM_CSS_SELECTOR_PREFIX}container">
    <div
      id="ps-desktop-widget__close"
      role="button"
      tabindex="0"
      aria-label="Dismiss popup"
      data-cy="popup-exit-button"
      @click=${onExit}
      @keydown=${handleKeyDownForClickableDivs}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <path
          d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
        />
      </svg>
    </div>
    <ps-popup-form
      .onExit=${onExit}
      .page=${page}
      .popupTemplateProps=${props}
      source="${V2_OPT_IN_SOURCES.DESKTOP}"
    ></ps-popup-form>
  </main>
`;

export default customFormWrapper;
