import { html, nothing } from 'lit';
export const renderBackgroundImageDiv = (finalStyleRules, styleElementName) => {
    var _a;
    if (!((_a = finalStyleRules[styleElementName]) === null || _a === void 0 ? void 0 : _a.backgroundImage)) {
        return nothing;
    }
    // The background image-related attributes are set as custom properties on the parent object.
    // For example, background-image is set to `--background-image`.  Then, .background-image then applies
    // those custom properties as the values to their respective CSS properties: background-image: var(--background-image);
    // This is only conditionally rendered to avoid cruft in the DOM when no background image is set.
    return html `<div class="background-image"></div>`;
};
