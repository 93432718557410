import { html, nothing } from 'lit';
import {
  FADE_IN_CLASS,
  handleKeyDownForClickableDivs,
} from '../../../helpers/ui';

type DoubleOptInSuccessScreenProps = {
  logoUrl: string;
  onExit: (hardClose?: boolean) => void;
};

/**
 * This is a v1 / v2 relic. Only v2 double opt-in uses this screen. We did not
 * invest time into recreating this success screen in the way we did for OTP.
 */
const doubleOptInSuccessScreen = ({
  logoUrl,
  onExit,
}: DoubleOptInSuccessScreenProps) => {
  const handleHardClose = () => onExit(true);

  return html`
    <main class="${FADE_IN_CLASS}">
      <div
        id="ps-desktop-widget__close"
        role="button"
        tabindex="0"
        aria-label="Dismiss popup"
        data-cy="popup-exit-button"
        @click=${handleHardClose}
        @keydown=${handleKeyDownForClickableDivs}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
      </div>
      ${logoUrl
        ? html`
            <header>
              <img
                alt="popup logo"
                id="ps-desktop-widget__logo-image"
                src="${logoUrl}"
              />
            </header>
          `
        : nothing}
      <section class="ps-section" data-cy="ps-success-section">
        <p id="ps-desktop-widget__success-pre-headline">Check YOUR 📱 AND</p>
        <h4 id="ps-desktop-widget__success-headline">REPLY "Y"</h4>
        <p id="ps-desktop-widget__success-pre-headline">TO FINISH SIGNING UP</p>
      </section>
      <form @submit=${handleHardClose}>
        <button
          @click=${handleHardClose}
          id="ps-desktop-widget__close-link"
          data-cy="ps-desktop-widget__close-link"
          type="button"
          aria-label="Dismiss popup"
        >
          Got It!
        </button>
      </form>
    </main>
  `;
};

export default doubleOptInSuccessScreen;
