import { html } from 'lit';

const phoneInput = ({
  isVisible = true,
  inputId = 'ps-desktop-widget__phone-input',
}) => html`
  <div
    id="ps-desktop-widget__phone-input-wrapper"
    data-cy="ps-desktop-phone-input-wrapper"
    style="width: 100%; ${isVisible ? '' : 'display: none;'}"
  >
    <input
      aria-label="Enter your phone number"
      aria-describedby="ps-desktop-widget__disclaimer-text"
      id="${inputId}"
      type="tel"
      data-cy="phone-input"
      data-popup-engagement="true"
    />
  </div>
`;

export default phoneInput;
