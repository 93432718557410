import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, DEFAULT_COLORS, MAIN_POPUP_CONTAINER_STYLE_ELEMENT, ROOT_BACKGROUND_IMAGE_DIV, ROOT_GRID_AREAS, ROOT_GRID_COLUMNS, ROOT_LAYOUTS, STYLE_ELEMENT_TYPES, STYLE_RULE_NAMES, } from '../constants';
import renderEditorNode from '../development/editorNode';
import { renderBackgroundImageDiv } from './helpers/backgroundImageDiv';
const ROOT_BLOCK_STYLE_ELEMENTS = Object.assign(Object.assign(Object.assign({}, ROOT_BACKGROUND_IMAGE_DIV), BLOCK_STYLE_ELEMENT), MAIN_POPUP_CONTAINER_STYLE_ELEMENT);
const defaultStyles = {
    [ROOT_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.POPUP_BG,
            // static
            [STYLE_RULE_NAMES.DISPLAY]: 'grid',
            [STYLE_RULE_NAMES.GRID_TEMPLATE_COLUMNS]: `repeat(${ROOT_GRID_COLUMNS}, 1fr)`,
            [STYLE_RULE_NAMES.OVERFLOW]: 'hidden',
            [STYLE_RULE_NAMES.POSITION]: 'relative',
            [STYLE_RULE_NAMES.ROOT_LAYOUT]: ROOT_LAYOUTS.NONE,
            [STYLE_RULE_NAMES.MAX_WIDTH]: '100%',
            [STYLE_RULE_NAMES.MIN_HEIGHT]: '100%',
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
    },
    [ROOT_BLOCK_STYLE_ELEMENTS.MAIN_POPUP_CONTAINER]: {
        mobile: {
            [STYLE_RULE_NAMES.LAYOUT_DIRECTION]: 'column',
            [STYLE_RULE_NAMES.LAYOUT_SPACING]: 'center',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '30px 30px 30px 30px',
            // static
            [STYLE_RULE_NAMES.DISPLAY]: 'flex',
            [STYLE_RULE_NAMES.GRID_AREA]: ROOT_GRID_AREAS.MAIN_POPUP_CONTAINER,
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
    },
    [ROOT_BLOCK_STYLE_ELEMENTS.ROOT_BACKGROUND_IMAGE_DIV]: {
        mobile: {
            [STYLE_RULE_NAMES.HEIGHT]: '200px',
            // static
            [STYLE_RULE_NAMES.BACKGROUND_POSITION]: '50% 50%',
            [STYLE_RULE_NAMES.BACKGROUND_SIZE]: 'cover',
            [STYLE_RULE_NAMES.DISPLAY]: 'block',
            [STYLE_RULE_NAMES.GRID_AREA]: ROOT_GRID_AREAS.DECORATIVE,
            [STYLE_RULE_NAMES.WIDTH]: '100%',
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
};
function isEmpty({ children = [] }) {
    return children.length === 0;
}
function render(renderData) {
    var _a;
    const { blockActions, classes, environment, finalStyleRules } = renderData;
    const rootLayout = (_a = finalStyleRules[STYLE_ELEMENT_TYPES.BLOCK]) === null || _a === void 0 ? void 0 : _a[STYLE_RULE_NAMES.ROOT_LAYOUT];
    const { block: blockClasses, rootBackgroundImageDiv: rootBackgroundImageDivClasses, editorNode: editorNodeClasses, mainPopupContainer: mainPopupContainerClasses, } = classes;
    return html `
    <div class=${classMap(blockClasses)}>
      ${renderBackgroundImageDiv(finalStyleRules, STYLE_ELEMENT_TYPES.BLOCK)}
      ${rootLayout !== ROOT_LAYOUTS.NONE
        ? html `<div class=${classMap(rootBackgroundImageDivClasses)}>
            ${renderBackgroundImageDiv(finalStyleRules, STYLE_ELEMENT_TYPES.ROOT_BACKGROUND_IMAGE_DIV)}
          </div>`
        : nothing}
      <form
        class=${classMap(mainPopupContainerClasses)}
        novalidate
        @click=${ifDefined(blockActions.selectBlock)}
        @submit=${(event) => event.preventDefault()}
      >
        ${renderEditorNode(editorNodeClasses, environment, blockActions)}
        ${renderData.children}
      </form>
    </div>
  `;
}
const rootTempBlockHelpers = {
    defaultStyles,
    isEmpty,
    render,
    styleElements: ROOT_BLOCK_STYLE_ELEMENTS,
};
export default rootTempBlockHelpers;
