import { css, unsafeCSS } from 'lit';
import { camelToKebab, getFlattenedViewportProps } from '..';
import SDKBlockHelpers from '../../blocks';
import { BLOCK_TYPES, CSS_VARS, POPUP_WRAPPER_ID, SCREEN_SIZES, STYLE_ELEMENT_TYPES, STYLE_RULE_NAMES, } from '../../constants';
import { CustomStyleRules } from './customStyleRules';
export const createBlockInstanceStyleElementClass = (block, styleElement) => `${styleElement}-${block.id}`;
/**
 * Converts style rules to a string CSS declarations. If the style rule name is
 * a custom one, we attempt to find a matching helper function in our custom
 * style rule map to generate the CSS, otherwise we just create a typical
 * key-val format string.
 */
export const createCSSFromStyleRules = (styleRules) => Object.entries(styleRules).reduce((acc, [styleRuleName, styleRuleValue]) => {
    var _a, _b;
    return `${acc}${(_b = (_a = CustomStyleRules[styleRuleName]) === null || _a === void 0 ? void 0 : _a.call(CustomStyleRules, styleRuleValue)) !== null && _b !== void 0 ? _b : `${camelToKebab(styleRuleName)}: ${styleRuleValue};`}`;
}, '');
export const groupStyleRules = (styleRules, variants = []) => {
    let variant = null;
    const initialStyleRules = {};
    Object.entries(styleRules).forEach(([styleRuleName, styleRuleValue]) => {
        var _a;
        if (styleRuleName === STYLE_RULE_NAMES.VARIANT) {
            variant = (_a = variants.find((v) => v.id === styleRuleValue)) !== null && _a !== void 0 ? _a : null;
        }
        else {
            initialStyleRules[styleRuleName] = styleRuleValue;
        }
    });
    return {
        variant,
        initial: initialStyleRules,
    };
};
export const combineStyleSources = (styleSources) => {
    var _a;
    return (Object.assign(Object.assign(Object.assign({}, styleSources.default), (((_a = styleSources.variant) === null || _a === void 0 ? void 0 : _a.styles) || {})), styleSources.instance));
};
export const getBlockStyleSources = (block, screenSize, theme) => {
    const blockStyles = {};
    const styleElements = SDKBlockHelpers.getStyleElements(block.type);
    const defaultStyles = SDKBlockHelpers.getDefaultStyles(block.type);
    const instanceStyles = block.styles;
    Object.values(styleElements).forEach((styleElementName) => {
        var _a, _b;
        const defaultStylesForElement = defaultStyles[styleElementName];
        const instanceStylesForElement = instanceStyles === null || instanceStyles === void 0 ? void 0 : instanceStyles[styleElementName];
        const variantsForElement = theme[styleElementName];
        const flattenedDefaultStyles = (_a = getFlattenedViewportProps(screenSize, defaultStylesForElement)) !== null && _a !== void 0 ? _a : {};
        const flattenedInstanceStyles = (_b = getFlattenedViewportProps(screenSize, instanceStylesForElement)) !== null && _b !== void 0 ? _b : {};
        const { initial: initialDefaultStyleRules } = groupStyleRules(flattenedDefaultStyles, variantsForElement);
        const { initial: initialInstanceStyleRules, variant } = groupStyleRules(flattenedInstanceStyles, variantsForElement);
        blockStyles[styleElementName] = {
            default: initialDefaultStyleRules,
            variant,
            instance: initialInstanceStyleRules,
        };
    });
    return blockStyles;
};
/**
 * Returns the final style rules object for a block in a specific viewport.
 *
 * Example return:
 * ```
 * { label: { fontSize: '16px', textAlign: 'center' } }
 * ```
 */
export const getFinalStyleRulesForBlock = (block, theme, viewport) => {
    const styleElementNames = Object.values(SDKBlockHelpers.getStyleElements(block.type));
    const styleSources = getBlockStyleSources(block, viewport, theme);
    const finalStyleRulesForBlock = {};
    styleElementNames.forEach((styleElementName) => {
        const styleSource = styleSources[styleElementName];
        if (styleSource) {
            const combinedStyles = combineStyleSources(styleSource);
            finalStyleRulesForBlock[styleElementName] = combinedStyles;
        }
    });
    return finalStyleRulesForBlock;
};
/**
 * Returns an object with a viewport(s) as key, and an array of final style rule
 * objects for each block in a popup for that viewport as value. By default,
 * both viewports are returned, but a specific one can be targeted.
 *
 * Example return:
 * ```
 *   {
 *      mobile: [
 *        { button: { fontSize: '16px' } },
 *        { input: { color: '#000000' } }
 *     ],
 *      desktop: [
 *        { button: { fontSize: '16px' } },
 *        { input: { color: '#FFFFFF' } }
 *      ],
 *   }
 * ```
 */
export const getFinalStyleRulesForPopup = ({ stepBlocks, teaserBlocks, theme, viewport, }) => {
    const shouldReturnMobile = !viewport || viewport === SCREEN_SIZES.MOBILE;
    const shouldReturnDesktop = !viewport || viewport === SCREEN_SIZES.DESKTOP;
    return {
        [SCREEN_SIZES.MOBILE]: shouldReturnMobile
            ? [...stepBlocks, ...teaserBlocks].map((block) => getFinalStyleRulesForBlock(block, theme, SCREEN_SIZES.MOBILE))
            : undefined,
        [SCREEN_SIZES.DESKTOP]: shouldReturnDesktop
            ? [...stepBlocks, ...teaserBlocks].map((block) => getFinalStyleRulesForBlock(block, theme, SCREEN_SIZES.DESKTOP))
            : undefined,
    };
};
/**
 * Returns an array of unique CSS values for a given style rule name from a
 * popup's blocks. By default, both viewports are considered, but a specific one
 * can be targeted.
 *
 * Example return:
 * ```
 * ['flex-start', 'center', 'flex-end']
 * ```
 */
export const getUniqueStyleRuleValuesInPopup = ({ stepBlocks, styleRuleName, teaserBlocks, theme, viewport, }) => {
    const { mobile = [], desktop = [] } = getFinalStyleRulesForPopup({
        stepBlocks,
        teaserBlocks,
        theme,
        viewport,
    });
    const uniqueStyleRuleValues = new Set();
    [...mobile, ...desktop].forEach((block) => {
        Object.values(block).forEach((styleRuleObj) => {
            const cssValue = styleRuleObj[styleRuleName];
            if (cssValue)
                uniqueStyleRuleValues.add(cssValue);
        });
    });
    return [...uniqueStyleRuleValues];
};
export const createInstanceCSSForBlock = (block, viewport, theme) => {
    let css = '';
    const finalStyleRules = getFinalStyleRulesForBlock(block, theme, viewport);
    Object.entries(finalStyleRules).forEach(([styleElementName, styleRules]) => {
        const selector = `.${createBlockInstanceStyleElementClass(block, styleElementName)}`;
        const finalStyleRulesAsCss = createCSSFromStyleRules(styleRules);
        css += `${selector} { ${finalStyleRulesAsCss} }\n`;
    });
    return css;
};
export const createInstanceCSSForBlocks = (blocks, viewport, theme) => blocks.reduce((acc, block) => {
    const css = createInstanceCSSForBlock(block, viewport, theme);
    return css ? `${acc} ${css}` : acc;
}, '');
/**
 * Get the layout direction for the block style element of a given block for a
 * given viewport size.
 */
export const getLayoutDirection = (block, { environment: { viewport }, theme, }) => {
    var _a;
    const finalStyles = getFinalStyleRulesForBlock(block, theme, viewport);
    /* TODO(fullscreen): once the original root block type has a main popup
    container, this check will need adjusted */
    return (_a = finalStyles[block.type === BLOCK_TYPES.ROOT_TEMP
        ? STYLE_ELEMENT_TYPES.MAIN_POPUP_CONTAINER
        : STYLE_ELEMENT_TYPES.BLOCK]) === null || _a === void 0 ? void 0 : _a.layoutDirection;
};
export const createBasePopupCSS = () => css `
  @keyframes spinToWin {
    0% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(var(${unsafeCSS(CSS_VARS.SPIN_TO_WIN_ROTATION)}));
    }
    100% {
      transform: rotate(var(${unsafeCSS(CSS_VARS.SPIN_TO_WIN_ROTATION)}));
    }
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  button {
    cursor: pointer;
  }

  ::placeholder {
    color: inherit;
  }

  .spin-to-win__animation-wrapper {
    position: relative;
    translate: 0 0 0; /* graphic chip support */
  }

  .spin-to-win__stopper-wrapper {
    inset: 0;
    position: absolute;
  }

  .animation {
    animation-fill-mode: forwards;
  }

  .animation--spin-to-win {
    animation-duration: var(${unsafeCSS(CSS_VARS.SPIN_TO_WIN_DURATION)});
    animation-name: spinToWin;
    animation-timing-function: cubic-bezier(0.15, -0.18, 0.1, 1.01);
  }

  /* Custom properties to forward background image properties to .background-image. */
  @property --background-image {
    syntax: '<image>';
    inherits: false;
    initial-value: unset;
  }

  @property --background-position {
    syntax: '<position>';
    inherits: false;
    initial-value: unset;
  }

  @property --background-size {
    syntax: 'contain | cover | auto | <length> | <percentage>';
    inherits: false;
    initial-value: unset;
  }

  @property --background-repeat {
    syntax: 'repeat | repeat-x | repeat-y | no-repeat';
    inherits: false;
    initial-value: unset;
  }

  @property --background-opacity {
    syntax: '<opacity-value>';
    inherits: true;
    initial-value: unset;
  }

  /* Background image base styling to allow controlling background image opacity. */
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: var(--background-position);
    background-image: var(--background-image);
    background-size: var(--background-size);
    background-repeat: var(--background-repeat);
    opacity: var(--background-opacity);
  }
  /* Reset background image properties to prevent cascade application for nested items. */
  *:has(> .background-image) {
    --background-opacity: 1;
    --background-image: unset;
    --background-position: unset;
    --background-size: unset;
  }
`;
export const createEditorCSS = () => css `
  /* Vars */
  #${unsafeCSS(POPUP_WRAPPER_ID)} {
    --drop-zone-size: 24px;
    --drop-zone-plus-size: 18px;
    --drop-zone-drag-border-size: 1px;
    --editor-node-button-size: 24px;
    --editor-node-selected-border-size: 2px;
    --editor-node-selected-inner-border-size: 1px;
    --editor-node-button-svg-size: calc(var(--editor-node-button-size) - 8px);
  }

  /* Editor node */
  .editor-node {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    outline: calc(
        var(--editor-node-selected-border-size) +
          var(--editor-node-selected-inner-border-size)
      )
      solid var(--purple-core);
    position: absolute;
    top: 0;
    transition: opacity 0.1s;
    width: 100%;
  }

  .editor-node::before {
    content: ' ';
    outline: var(--editor-node-selected-inner-border-size) solid var(--white);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .editor-node:hover {
    opacity: 1;
    z-index: 1;
  }

  .editor-node.selected,
  .editor-node.selected:hover {
    opacity: 1;
    z-index: 1;
  }

  /* Editor node button row */
  .editor-node__button-row {
    display: none;
  }

  .editor-node.selected > .editor-node__button-row {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }

  /* Editor node button */
  .editor-node__button {
    background-color: var(--purple-core);
    color: #fff;
    display: grid;
    height: var(--editor-node-button-size);
    margin-top: calc(
      -1 * var(--editor-node-button-size) - var(--editor-node-selected-border-size)
    );
    margin-right: calc(-1 * var(--editor-node-selected-border-size));
    place-content: center;
    width: var(--editor-node-button-size);
  }

  .editor-node__button-svg {
    height: var(--editor-node-button-svg-size);
    width: var(--editor-node-button-svg-size);
  }

  .root-block .editor-node,
  .container .editor-node {
    z-index: 0 !important;
  }

  /* Drop zone */
  .drop-zone {
    display: none;
    flex-shrink: 0;
    opacity: 0.5;
    position: relative;
    transition: 0.3s;
    transition-property: opacity;
  }

  .drop-zone.dragging {
    display: block;
    z-index: 1;
  }

  .drop-zone.dragging-over {
    opacity: 1;
    z-index: 2;
  }

  /* Drop zone dashed line */
  .drop-zone::before {
    content: '';
    display: block;
    height: var(--drop-zone-drag-border-size);
    left: 0;
    position: absolute;
    transition: transform 0.1s;
  }

  /* Drop zone plus */
  .drop-zone::after {
    background-color: white;
    border-radius: 50%;
    border: 2px solid var(--highlight-color);
    color: var(--highlight-color);
    content: '+';
    height: var(--drop-zone-plus-size);
    left: 50%;
    line-height: calc(var(--drop-zone-plus-size) - 7px);
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%) scale(0); /* order matters */
    transition-property: opacity, transform;
    transition: 0.1s;
    width: var(--drop-zone-plus-size);
  }

  .drop-zone.dragging-over::after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition-delay: 0.15s;
  }

  /*  Drop zone in vertical layout */
  .vertical-layout > .drop-zone {
    height: var(--drop-zone-size);
    margin-block: calc(-1 * var(--drop-zone-size) / 2);
  }

  .vertical-layout > .drop-zone::before {
    border-top: var(--drop-zone-drag-border-size) dashed black;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  .vertical-layout > .drop-zone.dragging-over::before {
    border-color: var(--highlight-color);
    border-top-style: solid;
    transform: translateY(-50%) scaleX(1.03) scaleY(3); /* order matters */
  }

  /* Drop zone in horizontal layout */
  .horizontal-layout > .drop-zone {
    margin-inline: calc(-1 * var(--drop-zone-size) / 2);
    width: var(--drop-zone-size);
  }

  .horizontal-layout > .drop-zone::before {
    border-left: var(--drop-zone-drag-border-size) dashed black;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .horizontal-layout > .drop-zone.dragging-over::before {
    border-color: var(--highlight-color);
    border-left-style: solid;
    transform: translateX(-50%) scaleY(1.03) scaleX(3); /* order matters */
  }

  /* Empty layout */
  .empty-layout > .drop-zone {
    border-radius: var(--border-radius-x-small);
    outline: var(--drop-zone-drag-border-size) dashed black;
    display: unset;
    height: 100%;
    left: 0;
    margin: unset;
    min-height: var(--drop-zone-size);
    min-width: var(--drop-zone-size);
    top: 0;
    width: 100%;
  }

  .empty-layout > .drop-zone.dragging-over {
    outline: 3px solid var(--highlight-color);
  }

  .empty-layout > .drop-zone::before {
    content: unset;
  }
`;
