export const DEFAULT_MOBILE_FONT_FAMILY = 'Open Sans Condensed';

export const DEFAULT_API_VALUES = {
  font: {
    desktop: 'Roboto',
    mobile: `${DEFAULT_MOBILE_FONT_FAMILY}:300`,
  },
  background_style: 'Color',
  button_radius: 10,
  two_touch_enabled: true,
};

export const LOWERCASE_POPUP_TYPES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
} as const;
