import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, BUTTON_STYLE_ELEMENT, BUTTON_TEXT_ELEMENT, CONFIG_ONCLICK_ACTIONS, DEFAULT_COLORS, STYLE_RULE_NAMES, SYSTEM_FONT_FAMILY_STYLE, } from '../constants';
import renderEditorNode from '../development/editorNode';
const BUTTON_BLOCK_TEXT_ELEMENTS = Object.assign({}, BUTTON_TEXT_ELEMENT);
const BUTTON_BLOCK_STYLE_ELEMENTS = Object.assign(Object.assign({}, BUTTON_STYLE_ELEMENT), BLOCK_STYLE_ELEMENT);
const defaultStyles = {
    [BUTTON_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.ALIGN_SELF]: 'center',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.WIDTH]: '100%',
            // private
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
    [BUTTON_BLOCK_STYLE_ELEMENTS.BUTTON]: {
        mobile: {
            // default variant uses the following styles
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.PRIMARY_BUTTON_BG,
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.PRIMARY_BUTTON_TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'bold',
            [STYLE_RULE_NAMES.PADDING]: '8px 8px 8px 8px',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
    },
};
const addBlockActions = ({ block, blockActions, popupActions, }) => {
    // eslint-disable-next-line no-param-reassign
    blockActions.onClick = () => {
        var _a, _b, _c;
        if (((_a = block.config) === null || _a === void 0 ? void 0 : _a.dataName) && ((_b = block.config) === null || _b === void 0 ? void 0 : _b.dataValue)) {
            popupActions.updateStepData(block.id, (_c = block.config) === null || _c === void 0 ? void 0 : _c.dataValue);
        }
        popupActions.handleButtonClick(block);
    };
};
function render(renderData) {
    var _a, _b, _c;
    const { block, blockActions: { handleReorderDragEnd, handleReorderDragStart, handleReorderDrop, onClick, selectBlock, }, classes: { block: blockClasses, button: buttonClasses, editorNode: editorNodeClasses, }, content, environment: { isDevelopment }, } = renderData;
    const { action } = (_b = (_a = block.config) === null || _a === void 0 ? void 0 : _a.onClick) !== null && _b !== void 0 ? _b : {};
    const buttonContent = (_c = content[BUTTON_BLOCK_TEXT_ELEMENTS.BUTTON]) !== null && _c !== void 0 ? _c : 'Button';
    const hasSubmitOnClickAction = action === CONFIG_ONCLICK_ACTIONS.SUBMIT_AND_ROUTE_TO_STEP;
    const hasCloseAction = action === CONFIG_ONCLICK_ACTIONS.CLOSE;
    const hasChangeLocationAction = action === CONFIG_ONCLICK_ACTIONS.CHANGE_LOCATION;
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(selectBlock)}
      @dragstart=${ifDefined(handleReorderDragStart)}
      @dragend=${ifDefined(handleReorderDragEnd)}
      @drop=${ifDefined(handleReorderDrop)}
    >
      ${renderEditorNode(editorNodeClasses, renderData.environment, renderData.blockActions)}
      <button
        class=${classMap(buttonClasses)}
        data-popup-engagement=${hasCloseAction ? nothing : 'true'}
        role=${ifDefined(hasChangeLocationAction ? 'link' : undefined)}
        type=${hasSubmitOnClickAction ? 'submit' : 'button'}
        @click=${ifDefined(onClick)}
      >
        ${buttonContent}
      </button>
    </div>
  `;
}
const buttonBlockHelpers = {
    addBlockActions,
    defaultStyles,
    render,
    styleElements: BUTTON_BLOCK_STYLE_ELEMENTS,
    textElements: BUTTON_BLOCK_TEXT_ELEMENTS,
};
export default buttonBlockHelpers;
