export const BLOCK_TYPES = {
    BUTTON: 'BUTTON',
    CLOSE_BUTTON: 'CLOSE_BUTTON',
    CONTAINER: 'CONTAINER',
    EMAIL_INPUT: 'EMAIL_INPUT',
    HEADING: 'HEADING',
    IMAGE: 'IMAGE',
    LOGO: 'LOGO',
    OTP_INPUT: 'OTP_INPUT',
    PHONE_INPUT: 'PHONE_INPUT',
    ROOT: 'ROOT',
    ROOT_TEMP: 'ROOT_TEMP',
    SPIN_TO_WIN: 'SPIN_TO_WIN',
    TEASER: 'TEASER',
    TEASER_ROOT: 'TEASER_ROOT',
    TEXT: 'TEXT',
};
export const OPT_IN_METHODS = {
    ONE_TIME_PASSCODE: 'ONE_TIME_PASSCODE',
};
export const SCREEN_SIZES = {
    MOBILE: 'mobile',
    DESKTOP: 'desktop',
};
// In order from least to most precedence
export const VIEWPORT_HIERARCHY = [
    SCREEN_SIZES.MOBILE,
    SCREEN_SIZES.DESKTOP,
];
export const STEP_ROLES = {
    DEFAULT: 'DEFAULT',
    ONSITE_PHONE: 'ONSITE_PHONE',
    ONSITE_VERIFY: 'ONSITE_VERIFY',
};
/*
  Content
*/
export const BUTTON_TEXT_ELEMENT = {
    BUTTON: 'button',
};
export const HEADING_TEXT_ELEMENT = {
    HEADING: 'heading',
};
export const LABEL_TEXT_ELEMENT = {
    LABEL: 'label',
};
export const PARAGRAPH_TEXT_ELEMENT = {
    PARAGRAPH: 'paragraph',
};
export const TEXT_ELEMENT_TYPES = Object.assign(Object.assign(Object.assign(Object.assign({}, BUTTON_TEXT_ELEMENT), HEADING_TEXT_ELEMENT), LABEL_TEXT_ELEMENT), PARAGRAPH_TEXT_ELEMENT);
/*
  Styles
*/
export const BLOCK_STYLE_ELEMENT = {
    BLOCK: 'block',
};
export const MAIN_POPUP_CONTAINER_STYLE_ELEMENT = {
    MAIN_POPUP_CONTAINER: 'mainPopupContainer',
};
export const BUTTON_STYLE_ELEMENT = {
    BUTTON: 'button',
};
export const HEADING_STYLE_ELEMENT = {
    HEADING: 'heading',
};
export const IMAGE_STYLE_ELEMENT = {
    IMAGE: 'image',
};
export const INPUT_STYLE_ELEMENT = {
    INPUT: 'input',
};
export const LABEL_STYLE_ELEMENT = {
    LABEL: 'label',
};
export const LINK_STYLE_ELEMENT = {
    LINK: 'link',
};
export const LOGO_STYLE_ELEMENT = {
    LOGO: 'logo',
};
export const PATH_STYLE_ELEMENT = {
    PATH: 'path',
};
export const ROOT_BACKGROUND_IMAGE_DIV = {
    ROOT_BACKGROUND_IMAGE_DIV: 'rootBackgroundImageDiv',
};
export const SMALL_STYLE_ELEMENT = {
    SMALL: 'small',
};
export const SPIN_TO_WIN_CENTER_ELEMENT = {
    SPIN_TO_WIN_CENTER: 'spinToWinCenter',
};
export const SPIN_TO_WIN_MAIN_WHEEL_ELEMENT = {
    SPIN_TO_WIN_MAIN_WHEEL: 'spinToWinMainWheel',
};
export const SPIN_TO_WIN_SECTION_BORDER_ELEMENT = {
    SPIN_TO_WIN_SECTION_BORDER: 'spinToWinSectionBorder',
};
export const SVG_STYLE_ELEMENT = {
    SVG: 'svg',
};
export const TEXT_STYLE_ELEMENT = {
    TEXT: 'text',
};
export const STYLE_ELEMENT_TYPES = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, BLOCK_STYLE_ELEMENT), BUTTON_STYLE_ELEMENT), HEADING_STYLE_ELEMENT), IMAGE_STYLE_ELEMENT), INPUT_STYLE_ELEMENT), LABEL_STYLE_ELEMENT), LINK_STYLE_ELEMENT), LOGO_STYLE_ELEMENT), MAIN_POPUP_CONTAINER_STYLE_ELEMENT), PATH_STYLE_ELEMENT), ROOT_BACKGROUND_IMAGE_DIV), SMALL_STYLE_ELEMENT), SPIN_TO_WIN_CENTER_ELEMENT), SPIN_TO_WIN_MAIN_WHEEL_ELEMENT), SPIN_TO_WIN_SECTION_BORDER_ELEMENT), SVG_STYLE_ELEMENT), TEXT_STYLE_ELEMENT);
/* Style rules that don't map to a camel cased version of a kebab cased CSS
property directly, or need some intermediary logic when creating the CSS values
for them. As we refactor our style rule dictionaries, this could go away. */
export const CUSTOM_STYLE_RULE_NAMES = {
    BACKGROUND_IMAGE: 'backgroundImage',
    BACKGROUND_OPACITY: 'backgroundOpacity',
    BACKGROUND_POSITION: 'backgroundPosition',
    BACKGROUND_REPEAT: 'backgroundRepeat',
    BACKGROUND_SIZE: 'backgroundSize',
    JUSTIFY_CONTENT: 'justify-content',
    LAYOUT_DIRECTION: 'layoutDirection',
    LAYOUT_SPACING: 'layoutSpacing',
    PLATFORM_SYNC: 'platformSync',
    ROOT_LAYOUT: 'rootLayout',
    SPIN_TO_WIN_DURATION: 'spinToWinDuration',
    TEASER_IFRAME_INSET: 'teaserIframeInset',
    VARIANT: 'variant',
};
export const STYLE_RULE_NAMES = Object.assign(Object.assign({}, CUSTOM_STYLE_RULE_NAMES), { ALIGN_ITEMS: 'alignItems', ALIGN_SELF: 'alignSelf', BACKGROUND_COLOR: 'backgroundColor', BLOCK_SIZE: 'blockSize', BORDER_COLOR: 'borderColor', BORDER_RADIUS: 'borderRadius', BORDER_STYLE: 'borderStyle', BORDER_WIDTH: 'borderWidth', BORDER_TOP_COLOR: 'borderTopColor', BORDER_TOP_STYLE: 'borderTopStyle', BORDER_TOP_WIDTH: 'borderTopWidth', COLOR: 'color', DISPLAY: 'display', FILL: 'fill', FLEX: 'flex', FLEX_GROW: 'flexGrow', FONT_FAMILY: 'fontFamily', FONT_SIZE: 'fontSize', FONT_STYLE: 'fontStyle', FONT_WEIGHT: 'fontWeight', GRID_AREA: 'gridArea', GRID_COLUMN: 'gridColumn', GRID_TEMPLATE_COLUMNS: 'gridTemplateColumns', HEIGHT: 'height', INSET: 'inset', LETTER_SPACING: 'letterSpacing', LINE_HEIGHT: 'lineHeight', MARGIN: 'margin', MAX_HEIGHT: 'maxHeight', MAX_WIDTH: 'maxWidth', MIN_HEIGHT: 'minHeight', MIN_WIDTH: 'minWidth', OBJECT_FIT: 'objectFit', OVERFLOW: 'overflow', OPACITY: 'opacity', PADDING: 'padding', POSITION: 'position', STROKE: 'stroke', STROKE_WIDTH: 'strokeWidth', TEXT_ALIGN: 'textAlign', TEXT_DECORATION: 'textDecoration', TEXT_TRANSFORM: 'textTransform', TEXT_WRAP: 'textWrap', TRANSFORM: 'transform', TRANSFORM_ORIGIN: 'transformOrigin', WIDTH: 'width', Z_INDEX: 'zIndex' });
export const DEFAULT_TEASER_POSITION = 'auto auto 16px 16px';
export const DEFAULT_COLORS = {
    BORDER: '#000000',
    INPUT_BG: '#ECECEC',
    SPIN_TO_WIN_CENTER_BG: '#000000',
    POPUP_BG: '#FFFFFF',
    PRIMARY_BUTTON_BG: '#656565',
    PRIMARY_BUTTON_TEXT: '#FFFFFF',
    SECONDARY_BUTTON_BG: 'transparent',
    SECONDARY_BUTTON_TEXT: '#656565',
    STOPPER: '#8D69F8',
    TEASER_CLOSE_BUTTON_BG: 'rgba(255,255,255, 0.8)',
    TEXT: '#000000',
};
// TODO(Kameron Ahler): this should probably be plural
export const SYSTEM_FONT_FAMILY_STYLE = {
    ARIAL: `'Arial', sans-serif`,
    CENTURY_GOTHIC: `'Century Gothic', sans-serif`,
    COURIER: `'Courier', monospace`,
    GEORGIA: `'Georgia', serif`,
    HELVETICA: `'Helvetica', sans-serif`,
    VERDANA: `'Verdana', sans-serif`,
};
export const RESERVED_DATA_NAMES = {
    EMAIL: 'email',
    PHONE: 'phone',
    OTP: 'otp',
};
export const CLOSE_BEHAVIORS = {
    HARD_CLOSE: 'HARD_CLOSE',
    TEASER: 'TEASER',
};
// This is <postscript-popup> > div#postscript-popup, the highest div in tree
export const POPUP_WRAPPER_ID = 'postscript-popup';
export const CONFIG_ONCLICK_ACTIONS = {
    CHANGE_LOCATION: 'changeLocation',
    CLOSE: 'close',
    ROUTE_TO_STEP: 'routeToStep',
    SUBMIT_AND_ROUTE_TO_STEP: 'submitAndRouteToStep',
};
export const CONFIG_ONCLICK_ROUTE_DIRECTIONS = {
    NEXT: 'next',
    PREVIOUS: 'previous',
};
export const TRIGGER_TYPES = {
    DELAY: 'Delay',
};
export const SUBSCRIBER_COHORTS = {
    NEW_SMS_USERS: 'NEW_SMS_USERS',
    EVERYONE: 'EVERYONE',
    EXISTING_SMS_SUBSCRIBERS: 'EXISTING_SMS_SUBSCRIBERS',
};
export const PLATFORM = {
    ALL: 'ALL',
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE',
};
export const BLOCK_POPUP_LOCK_STATUSES = {
    LOCKED: 'LOCKED',
    UNLOCKED: 'UNLOCKED',
};
export const BLOCK_POPUP_STATUSES = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};
export const INCLUSION_CRITERIA_OPTIONS = {
    INCLUDED: 'INCLUDED',
    EXCLUDED: 'EXCLUDED',
};
export const OTP_VERIFY_ERROR_STYLES = `background-color: #FFE5EA; color: #AA1E39; display: block; font-family: 'Inter', sans-serif; font-size: 10px; line-height: 12px; margin-block: 8px; padding: 3px 6px; text-align: left;`;
export const OTP_LENGTH_ERROR_MESSAGE = 'Please enter the complete code';
export const PHONE_REQUIRED_ERROR_MESSAGE = 'Please enter a valid phone number';
export const GENERAL_REQUIRED_ERROR_MESSAGE = 'This field is required';
export const DISCLAIMER_TERMS_TAG = '{terms_of_service}';
export const DISCLAIMER_PRIVACY_TAG = '{privacy_policy}';
export const ROOT_GRID_COLUMNS = 12;
export const ROOT_GRID_AREAS = {
    DECORATIVE: 'DECORATIVE',
    MAIN_POPUP_CONTAINER: 'MAIN_POPUP_CONTAINER',
};
export const ROOT_LAYOUTS = {
    BOTTOM_DECORATIVE: 'BOTTOM_DECORATIVE',
    LEFT_DECORATIVE: 'LEFT_DECORATIVE',
    NONE: 'NONE',
    RIGHT_DECORATIVE: 'RIGHT_DECORATIVE',
    TOP_DECORATIVE: 'TOP_DECORATIVE',
};
/* These spin-to-win svg values shouldn't ever need to change. The svg will
scale. */
export const SPIN_TO_WIN_DIAMETER = 100;
export const SPIN_TO_WIN_RADIUS = SPIN_TO_WIN_DIAMETER / 2;
export const SPIN_TO_WIN_RADIUS_ADJUSTED_FOR_STROKE_WIDTH = SPIN_TO_WIN_RADIUS / 2;
export const SPIN_TO_WIN_CIRCUMFERENCE = 2 * Math.PI * SPIN_TO_WIN_RADIUS_ADJUSTED_FOR_STROKE_WIDTH;
export const SPIN_TO_WIN_DEFAULT_ROTATIONS = 7;
export const SPIN_TO_WIN_STOPPER_SIZE = '12.5%';
export const SPIN_TO_WIN_STOPPER_POSITIONS = {
    BOTTOM: 'BOTTOM',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    TOP: 'TOP',
};
export const SPIN_TO_WIN_STOPPER_TYPES = {
    TEAR: 'TEAR',
    TEAR_ROUNDED: 'TEAR_ROUNDED',
    TRIANGLE: 'TRIANGLE',
    TRIANGLE_ROUNDED: 'TRIANGLE_ROUNDED',
};
export const CSS_VARS = {
    SPIN_TO_WIN_DURATION: '--spin-to-win-duration',
    SPIN_TO_WIN_ROTATION: '--spin-to-win-rotation',
};
export const RICH_TEXT_CONTENT_FEATURE_FLAG = 'popup_rich_text_content';
export const PLATFORM_SYNC = { ENABLED: 'enabled', DISABLED: 'disabled' };
