import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, DEFAULT_COLORS, DISCLAIMER_PRIVACY_TAG, DISCLAIMER_TERMS_TAG, INPUT_STYLE_ELEMENT, LABEL_STYLE_ELEMENT, LABEL_TEXT_ELEMENT, LINK_STYLE_ELEMENT, PHONE_REQUIRED_ERROR_MESSAGE, SMALL_STYLE_ELEMENT, STYLE_RULE_NAMES, SYSTEM_FONT_FAMILY_STYLE, } from '../constants';
import renderEditorNode from '../development/editorNode';
import { validateRequired } from '../utils/data';
import '../web-components/PhoneInputWrapper';
import { renderError } from '../utils/errorOrSuccess';
const PHONE_INPUT_TEXT_ELEMENTS = Object.assign({}, LABEL_TEXT_ELEMENT);
const PHONE_INPUT_STYLE_ELEMENTS = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, BLOCK_STYLE_ELEMENT), INPUT_STYLE_ELEMENT), LABEL_STYLE_ELEMENT), LINK_STYLE_ELEMENT), SMALL_STYLE_ELEMENT);
const defaultStyles = {
    [PHONE_INPUT_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 8px 0px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            // private
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
    [PHONE_INPUT_STYLE_ELEMENTS.LABEL]: {
        mobile: {
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '14px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 4px 0px',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'left',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'block',
            [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
        },
    },
    [PHONE_INPUT_STYLE_ELEMENTS.INPUT]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.INPUT_BG,
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'left',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
    },
    [PHONE_INPUT_STYLE_ELEMENTS.SMALL]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLE.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '10px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.MARGIN]: '8px 0px 0px 0px',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'block',
            [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
        },
    },
    [PHONE_INPUT_STYLE_ELEMENTS.LINK]: {
        mobile: {
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            // private
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'underline',
            [STYLE_RULE_NAMES.TEXT_TRANSFORM]: 'uppercase',
        },
    },
};
const addBlockActions = ({ block, blockActions, popupActions, }) => {
    // eslint-disable-next-line no-param-reassign
    blockActions.updateStepData = (event) => {
        var _a, _b;
        const { value } = event.target;
        const formattedValue = (_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.closest('ps-phone-input-block-iti-wrapper')) === null || _b === void 0 ? void 0 : _b.getFormattedInputValue();
        popupActions.updateStepData(block.id, formattedValue !== null && formattedValue !== void 0 ? formattedValue : value);
    };
};
const validateBlockData = (block, data) => {
    var _a;
    const requiredErrorMessage = validateRequired(block, data, PHONE_REQUIRED_ERROR_MESSAGE);
    const lengthValidationErrorMessage = 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_a = document.querySelector('ps-phone-input-block-iti-wrapper')) === null || _a === void 0 ? void 0 : _a.getValidationError();
    return requiredErrorMessage !== null && requiredErrorMessage !== void 0 ? requiredErrorMessage : lengthValidationErrorMessage;
};
const getDisclaimerRenderData = (disclaimerObj) => {
    const { privacy_policy: privacyPolicy, terms_of_service: termsOfService, text, } = disclaimerObj;
    return {
        disclaimerText: {
            preTerms: text.split(DISCLAIMER_TERMS_TAG)[0],
            terms: termsOfService.text,
            betweenTermsAndPrivacy: text
                .split(DISCLAIMER_TERMS_TAG)[1]
                .split(DISCLAIMER_PRIVACY_TAG)[0],
            privacy: privacyPolicy.text,
            postPrivacy: text.split(DISCLAIMER_PRIVACY_TAG)[1],
        },
        disclaimerUrls: {
            terms: termsOfService.url,
            privacy: privacyPolicy.url,
        },
    };
};
// This is mostly to satisfy TS
const fallbackItiStyles = {
    color: '',
    fontFamily: '',
    fontSize: '',
    margin: '',
};
function render(renderData) {
    var _a, _b, _c;
    const { block, blockActions, classes, content, disclaimer, environment, finalStyleRules, state, } = renderData;
    const { block: blockClasses, editorNode: editorNodeClasses, input: inputClasses, label: labelClasses, link: linkClasses, small: smallClasses, } = classes;
    const labelContent = (_a = content[PHONE_INPUT_TEXT_ELEMENTS.LABEL]) !== null && _a !== void 0 ? _a : 'Phone';
    const { disclaimerText, disclaimerUrls } = getDisclaimerRenderData(disclaimer);
    const finalInputStyleRules = (_b = finalStyleRules[PHONE_INPUT_STYLE_ELEMENTS.INPUT]) !== null && _b !== void 0 ? _b : fallbackItiStyles;
    const inputId = `input-${block.id}`;
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${renderEditorNode(editorNodeClasses, environment, blockActions)}

      <label for=${inputId} class=${classMap(labelClasses)}>
        ${labelContent}
      </label>

      <ps-phone-input-block-iti-wrapper
        .countryCode="${environment.currentCountry}"
        .inputId="${inputId}"
        .finalInputStyleRules=${finalInputStyleRules}
        .viewport=${environment.viewport}
      >
        <style>
          ps-phone-input-block-iti-wrapper {
            display: block;
          }

          ps-phone-input-block-iti-wrapper .iti {
            width: 100%;
          }

          ps-phone-input-block-iti-wrapper
            .iti--show-selected-dial-code
            .iti__selected-country,
          ps-phone-input-block-iti-wrapper
            .iti--allow-dropdown
            .iti__country-container:hover
            .iti__selected-country {
            background-color: transparent;
          }

          ps-phone-input-block-iti-wrapper .iti__country-container {
            color: ${finalInputStyleRules.color};
            font-family: ${finalInputStyleRules.fontFamily};
            font-size: ${finalInputStyleRules.fontSize};
          }

          ps-phone-input-block-iti-wrapper .iti__arrow {
            border-top-color: ${finalInputStyleRules.color};
          }

          .iti--container {
            font-family: ${finalInputStyleRules.fontFamily};
            font-size: ${finalInputStyleRules.fontSize};
          }
        </style>
        <input
          aria-describedby=${(state === null || state === void 0 ? void 0 : state.error)
        ? `${inputId}-error`
        : `${inputId}-disclaimer`}
          aria-invalid=${ifDefined(!!(state === null || state === void 0 ? void 0 : state.error))}
          autocomplete=${environment.isDevelopment ? null : 'tel-national'}
          class=${classMap(inputClasses)}
          data-popup-engagement="true"
          id=${inputId}
          type="tel"
          @change=${ifDefined(blockActions.updateStepData)}
          required=${ifDefined(((_c = block.config) === null || _c === void 0 ? void 0 : _c.required) ? true : undefined)}
        />
      </ps-phone-input-block-iti-wrapper>

      ${renderError(state, inputId)}

      <small
        class=${classMap(smallClasses)}
        id="${inputId}-disclaimer"
        style="text-wrap: balance; text-wrap: pretty;"
      >
        ${disclaimerText.preTerms}
        <a
          aria-label=${`${disclaimerText.terms} (view in new window)`}
          class=${classMap(linkClasses)}
          data-popup-engagement="true"
          href=${ifDefined(disclaimerUrls.terms)}
          rel="noopener"
          target="_blank"
          >${disclaimerText.terms}</a
        >
        ${disclaimerText.betweenTermsAndPrivacy}
        <a
          aria-label=${`${disclaimerText.privacy} (view in new window)`}
          class=${classMap(linkClasses)}
          data-popup-engagement="true"
          href=${ifDefined(disclaimerUrls.privacy)}
          rel="noopener"
          target="_blank"
          >${disclaimerText.privacy}</a
        >${disclaimerText.postPrivacy}
      </small>
    </div>
  `;
}
const phoneInputBlockHelpers = {
    addBlockActions,
    defaultStyles,
    render,
    styleElements: PHONE_INPUT_STYLE_ELEMENTS,
    textElements: PHONE_INPUT_TEXT_ELEMENTS,
    validateBlockData,
};
export default phoneInputBlockHelpers;
