import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, BUTTON_STYLE_ELEMENT, DEFAULT_COLORS, STYLE_RULE_NAMES, SVG_STYLE_ELEMENT, } from '../constants';
import renderEditorNode from '../development/editorNode';
const CLOSE_BUTTON_BLOCK_STYLE_ELEMENTS = Object.assign(Object.assign(Object.assign({}, BUTTON_STYLE_ELEMENT), BLOCK_STYLE_ELEMENT), SVG_STYLE_ELEMENT);
const defaultStyles = {
    [CLOSE_BUTTON_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.OPACITY]: '1',
            // private
            [STYLE_RULE_NAMES.INSET]: '10px 10px auto auto',
            [STYLE_RULE_NAMES.POSITION]: 'absolute',
            [STYLE_RULE_NAMES.Z_INDEX]: '10',
        },
    },
    [CLOSE_BUTTON_BLOCK_STYLE_ELEMENTS.BUTTON]: {
        mobile: {
            // used for close icon button variant
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.PADDING]: '3px 3px 3px 3px',
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'flex',
            [STYLE_RULE_NAMES.JUSTIFY_CONTENT]: 'center',
        },
    },
    [CLOSE_BUTTON_BLOCK_STYLE_ELEMENTS.SVG]: {
        mobile: {
            // private
            [STYLE_RULE_NAMES.COLOR]: 'currentColor',
            [STYLE_RULE_NAMES.HEIGHT]: '18px',
            [STYLE_RULE_NAMES.WIDTH]: '18px',
        },
    },
};
const addBlockActions = ({ block, blockActions, popupActions, }) => {
    // eslint-disable-next-line no-param-reassign
    blockActions.onClick = () => popupActions.handleButtonClick(block);
};
function render(renderData) {
    const { block: { type }, blockActions, classes, environment, } = renderData;
    const { block: blockClasses, button: buttonClasses, editorNode: editorNodeClasses, svg: svgClasses, } = classes;
    return html `
    <div
      class=${classMap(blockClasses)}
      @click=${ifDefined(blockActions.selectBlock)}
    >
      ${renderEditorNode(editorNodeClasses, environment, blockActions)}
      <button
        aria-label="Dismiss popup"
        class=${classMap(buttonClasses)}
        data-block-type=${type}
        type="button"
        @click=${ifDefined(blockActions.onClick)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
          class=${classMap(svgClasses)}
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  `;
}
const closeButtonBlockHelpers = {
    addBlockActions,
    defaultStyles,
    render,
    styleElements: CLOSE_BUTTON_BLOCK_STYLE_ELEMENTS,
};
export default closeButtonBlockHelpers;
