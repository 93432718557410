/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import { html, render } from 'lit';
import { setTag } from '@sentry/browser';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import {
  DEFAULT_EXCLUDED_HOSTS,
  POPUP_DESKTOP_WIDGET_CONTAINER,
  POPUP_EVENT_TYPES,
  POPUP_HARD_CLOSE_STATE,
  POPUP_SOFT_CLOSE_STATE,
  POPUP_STATUS_COOKIE_NAME_PREFIX,
} from '../../../helpers/constants';
import {
  FADE_OUT_CLASS,
  delayUntilPopupOpen,
  focusFirstPopUpInput,
} from '../../../helpers/ui';
import {
  hideIframe,
  requestFocusedElement,
  restoreFocusedElement,
  resizeIframeForFullScreen,
  showIframe,
  makeIframeVisible,
  resizeIframeForSoftClose,
  sendPopupCloseEvent,
} from '../../../helpers/iframe';
import {
  delay as delayTimeout,
  generateScreensList,
  loadFont,
} from '../../../helpers/utility';
import { clearActivePopupContext } from '../../common/helpers/popupBase';
import {
  getPopupElementById,
  handleNextScreenTransition,
} from '../../helpers/next-screen-helper';
import {
  impressionEvent,
  setPopupCookie,
  trackPopupEvent,
} from '../../../helpers/events';
import dynamicStyles from '../components/dynamicStyles';
import { closedBubbleV2 } from '../components/closedBubble';
import main from '../components/main';
import { createEngagementTracker } from '../../../helpers/engagementTracker';
import {
  getFriendlyNameForPopupStatus,
  popupStateStore,
} from '../../common/helpers/stateManager';
import { getPopupForPreview } from '../../../services/popup/helper';
import { getCookieValueByKey } from '../../../helpers/utils';

let lastFocusedElementId: any;

const storeLastFocusedElementId = (elementId: string | null) => {
  lastFocusedElementId = elementId;
};

const FADE_OUT_MS = 500;
const INITIAL_BUBBLE_SIZE_TIMEOUT = 500;
const DESKTOP_BODY_CLASS = 'ps-desktop-widget__closed-body';
const DESKTOP_BUTTON_CLASS = 'ps-desktop-widget__close-widget';

export const renderV2DesktopPopupPreview = (
  previewData: any,
  shopId: any,
  status: any,
  sessionId: any,
) => {
  const popup = getPopupForPreview(previewData);
  const currentCountry = null;
  initializeV2DesktopPopup(
    popup,
    shopId,
    status,
    sessionId,
    previewData,
    currentCountry,
  );
};

export const renderV2DesktopPopup = (args: any) => {
  const {
    isOpen = true,
    shopId,
    currentCountry,
    closeAction,
    backgroundImage,
    font,
    backgroundStyle,
    backgroundColor,
    borderRadius,
    buttonRadius,
    textColor,
    buttonBackgroundColor,
    buttonTextColor,
    bubbleText,
    customCss,
    popupSize,
    screen,
    screensList,
    id: popupId,
    name: popupName,
    engagementTracker,
    sideImgUrl,
    logoUrl,
    styleDefaultVersion,
    popupType,
  } = args;

  const reRender = (state: any) => {
    if (state === null) {
      // handle fade out
      const rootElement = document.getElementById(
        POPUP_DESKTOP_WIDGET_CONTAINER,
      );

      if (rootElement) {
        rootElement.classList.add(`${FADE_OUT_CLASS}`);
      }

      setTimeout(() => hideIframe(), FADE_OUT_MS);

      return delayTimeout(FADE_OUT_MS);
    }

    render(renderV2DesktopPopup(state), document.body);

    if (state.isOpen) {
      resizeIframeForFullScreen();
    } else {
      resizeIframeForSoftClose(DESKTOP_BODY_CLASS, DESKTOP_BUTTON_CLASS);
    }
  };

  const unmount = () => reRender(null);

  const nextScreen = () => {
    const newScreen = handleNextScreenTransition(
      screen,
      screensList,
      popupId,
      unmount,
    );

    if (!newScreen) return;

    reRender({
      ...args,
      screen: newScreen,
    });

    setTimeout(
      () =>
        focusFirstPopUpInput(
          getPopupElementById(POPUP_DESKTOP_WIDGET_CONTAINER),
        ),
      0,
    );
  };

  const onExit = (hardExit: boolean) => {
    engagementTracker.endSession();

    if (closeAction === 'Exit' || hardExit === true) {
      setPopupCookie(POPUP_HARD_CLOSE_STATE, popupId);
      sendPopupCloseEvent(popupId, popupName, true);
      unmount();
      restoreFocusedElement(lastFocusedElementId);
      return;
    }

    setPopupCookie(POPUP_SOFT_CLOSE_STATE, popupId);
    sendPopupCloseEvent(popupId, popupName, false);

    const state = {
      ...args,
      isOpen: false,
    };

    reRender(state);
    restoreFocusedElement(lastFocusedElementId);
  };

  const onOpen = () => {
    const state = {
      ...args,
      isOpen: true,
    };

    impressionEvent(shopId, currentCountry, popupId, popupName, true);

    if (lastFocusedElementId) {
      requestFocusedElement(storeLastFocusedElementId);
    }

    reRender(state);
    delayUntilPopupOpen(() =>
      focusFirstPopUpInput(getPopupElementById(POPUP_DESKTOP_WIDGET_CONTAINER)),
    );
  };

  const customStyles = dynamicStyles({
    customCss,
    font,
    backgroundColor,
    backgroundImage,
    backgroundStyle,
    borderRadius,
    buttonRadius,
    buttonBackgroundColor,
    buttonTextColor,
    headlineColor: textColor,
    exitButtonColor: textColor,
    fullscreen: popupSize === 'Fullscreen',
    sideImgUrl,
    logoUrl,
    styleDefaultVersion,
    popupSize,
    popupType,
    textColor,
  });

  if (!isOpen)
    return html`${customStyles}${closedBubbleV2({
      text: bubbleText,
      onOpen,
      onExit: () => onExit(true),
    })}`;

  return html`
    ${customStyles}
    ${main({
      nextScreen,
      onExit,
      ...args,
    })}
  `;
};

export const initializeV2DesktopPopup = async (
  popup: any,
  shopId: any,
  statuses: any,
  sessionId: any,
  previewData: any,
  currentCountry: any,
) => {
  clearActivePopupContext();
  let isOpen = true;

  setTag('popup_id', popup.id);

  const {
    font,
    collectionAttributes,
    excludedHosts = DEFAULT_EXCLUDED_HOSTS,
    id: popupId,
    name: popupName,
  } = popup;

  // Excluded hosts allow us to prevent popup rendering on certain sites
  if (excludedHosts.includes(window.location.host)) return;

  const currentPopupStatus = getCookieValueByKey(
    POPUP_STATUS_COOKIE_NAME_PREFIX,
    popupId,
    statuses,
  );

  if (collectionAttributes && collectionAttributes[0] === 'closed') {
    isOpen = false;
  }

  loadFont(font);

  if (!previewData && currentPopupStatus === POPUP_SOFT_CLOSE_STATE) {
    isOpen = false;
  }

  const engagementTracker = createEngagementTracker(() =>
    trackPopupEvent(
      POPUP_EVENT_TYPES.ENGAGEMENT,
      shopId,
      popupId,
      currentCountry,
    ),
  );

  const state = {
    ...popup,
    screen: collectionAttributes[0],
    screensList: generateScreensList(popup),
    shopId,
    isOpen,
    currentCountry,
    sessionId,
    engagementTracker,
    terms: popup.terms ? html`${unsafeHTML(popup.terms)}` : undefined,
  };

  render(renderV2DesktopPopup(state), document.body);

  resizeIframeForFullScreen();

  if (!isOpen) {
    // We need to first display the iframe so it can be properly sized then resize it and make it visible (opacity)
    showIframe();
    // Wait 500ms before resizing the iframe to allow the iframe to load before resizing it
    setTimeout(
      () => {
        resizeIframeForSoftClose(DESKTOP_BODY_CLASS, DESKTOP_BUTTON_CLASS);
        makeIframeVisible();
      },
      previewData ? 0 : INITIAL_BUBBLE_SIZE_TIMEOUT,
    );
  } else {
    showIframe();
    makeIframeVisible();

    const popupRootNode = document.querySelector('main');
    engagementTracker.startSession(popupRootNode);
    impressionEvent(shopId, currentCountry, popupId, popupName);
    requestFocusedElement(storeLastFocusedElementId);
    delayUntilPopupOpen(() =>
      focusFirstPopUpInput(getPopupElementById(POPUP_DESKTOP_WIDGET_CONTAINER)),
    );
  }

  popupStateStore.updateState({
    id: popup.id,
    status: getFriendlyNameForPopupStatus(currentPopupStatus),
    engagementTracker,
  });
};
